.rsb-modal {
  & .modal-content {
    border-radius: 20px;
  }

  & &__header {
    border-bottom: none;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .btn-close {
      position: absolute;
      left: 100%;
      top: -1em;
      width: 24px;
      height: 24px;
      padding: 0;
      border-radius: 100%;
      border: none;
      background: #D9D9D9;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &:before, &:after {
        position: absolute;
        left: 12px;
        bottom: 5px;
        content: ' ';
        height: 15px;
        width: 1px;
        background-color: #5C6D76;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    &.colored {
      background: #07ACA6;
      color: #FFFFFF;
    }
  }
}
