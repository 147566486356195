.history-wrap {
    .history-head-wrap {
        margin-bottom: 47px;

        .history-title {
            font-size: 40px;
            color: #000;
            margin: 0;
            text-align: center;
        }

        .history-desc {
            font-size: 16px;
            color: #000;
            margin: 0;
            text-align: center;
        }
    }
    
    .timeline {
        z-index: 0;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-left: 2px;
        margin-left: 20px;
        border-left: 1px solid #e0e0e0;
    }
      
    .timeline-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
    }
      
    .timeline-dot {
        width: 12px;
        height: 12px;
        border: 3px solid #1890FF;
        z-index: 1;
        border-radius: 50%;
        position: relative;
        left: -8px;
    }
      
    .timeline-content {
        margin-left: 10px;
        margin-top: -5px;
    }
      
    .timeline-title {
        font-size: 18px;
        font-weight: 700;
        color: #000;
        margin: 0 0 5px;
    }
      
    .timeline-date {
        font-size: 18px;
        color: #000;
        margin: 0;
    }      
}   