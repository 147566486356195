@import 'src/styles/mixins';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  // position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 998;

  &__burger {
    width: 40px;
    height: 20px;
    position: relative;
    margin-right: 32px;
    display: none;
    cursor: pointer;

    &::after, &::before {
      content: '';
    }

    &::before {
      top: 0;
    }

    &::after {
      top: 100%;
    }

    span, &::after, &::before {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #131313;
      position: absolute;
      left: 0;
      transform: translateY(-50%);
    }

    span {
      top: 50%;
    }

    @include phone {    
      display: block;
    }
  }

  .main-header {
    display: flex;
    align-items: stretch;
    background-color: #064760;
    justify-content: space-between;
    flex-basis: 100%;
    padding: 10px 32px 10px 20px;
    max-height: 96px;

    @include phone {    
      padding: 8px 16px;
    }

    &__company-name {
      display: flex;
      align-items: center;

      .header-title {
        cursor: pointer;
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 500;
      }

      @include phone {
        max-height: 50px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      z-index: 1;
      margin-left: 1rem;

      &-login {
        color: #FFFFFF;
      }

      @include phone {    
        width: 100%;
        justify-content: space-between;
      }
    }

    @include phone {    
      flex-direction: column;
      align-items: center;
    }
  }

  &__navigation {
    height: 80px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

    @include phone {    
      align-items: center;
      justify-content: space-between;
    }
  }

  .logo {
    display: flex;
    align-items: center;
    z-index: 1;
    margin-right: 15px;

    @include tablet {    
      margin: 10px 12px 0 26px;
    }
  }

  &__mobile-menu {
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, .7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    backdrop-filter: blur(8px);

    .navigation {
      height: 100%;
      display: block;

      & > div {
        margin-top: 72px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__mobile-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    color:#131313;
    font-size: 38px !important;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .header .main-header {
    padding: 10px 32px 10px 0;
  }
  .header-title {
    display: none;
  }
}

@media (max-width: 600px) {
  .header .main-header {
    padding: 10px;
    max-height: unset;
  }
  .header-title {
    display: unset;
  }
  .header__navigation {
    padding-left: 16px;
  }
}

@media (max-width: 400px) {
  .header .main-header__company-name .header-title {
    font-size: 16px;
  }
  .header .logo { 
    margin: 0;
  }
  .header .main-header__actions { 
    justify-content: center;
  }
}