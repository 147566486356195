.search {
    user-select: none;
  
    &__input {
      height: 40px;
      min-width: 276px;
      padding-left: 0;
      outline: none;
      border: none !important;
      background-color: #455A641A !important;
    }
  
    &__icon {
      padding: 0 0 0 15px;
      user-select: none;
    }
  }
  