.main-reg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-reg {
    display: flex;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .second-reg {
    flex-direction: column;
    width: 95vw;

    .steps-reg {
      width: inherit;
    }

    .form-reg {
      width: inherit;
    }

    .header-form {
      width: fit-content;
    }
    
    .form-reg > div {
      width: -webkit-fill-available;

      .form-title--reg {
        width: fit-content;
      }

      .field-input {
        font-weight: 500;
        width: 75vw;
      }

      .pdf-uploader--field {
        width: unset;

        .drop-area {
          margin-left: 0;
        }
      }

      .next-btn {
        margin-left: 0;
      }
    }
  }
}
