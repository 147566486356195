@import "../../../../styles/colors";

.pagination_navigation {
  display: flex;
  flex-direction: row;

  &__element {
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    &.string {
      min-width: 100px;
    }
    &:hover {

    }
    &.clear_button_lock {
      cursor: no-drop;
      span {
        color: $LOCK;
      }
    }
  }
  &.lock {
    .pagination_navigation__element {

    }
  }
}
.show_by {
  margin: 0 16px;
  .title {
    white-space: nowrap;
    align-items: center;
    display: flex;
  }
}

.noData {
  .ant-pagination-item {
    font-weight: 500;
    background-color: #ffffff;
    border-color: #07aca6;

    a {
      color: #07aca6;
    }
  }
}