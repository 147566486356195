.c-transfer {
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    margin-top: 16px;
    border-radius: 20px;
    padding: 0 0 40px;
    display: flex;
    max-width: 985px;
    width: 100%;
  }

  &__row {
    display: flex;
    column-gap: 40px;
    width: 100%;
  }

  &__col {
    width: 100%;
  }

  &__form {
    width: 100%;
  }

  &__form__header {
    cursor: default;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: 1.45em;
  }
}

.c-transfer-radio {

}

.c-transfer-checkbox {

}

.c-transfer-info {
  cursor: default;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--grey-50, #F6F7F8);
  margin-bottom: 30px;
  min-width: 444px;

  &__blocked {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    font-size: 14px;
    border-radius: 8px;
    font-weight: 500;
    background: #fff;
    padding: 10px 12px;
  }

  &__header {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: .01em;
    margin-bottom: 1em;
    margin-top: .4em;

    &:first-child {
      margin-top: 0;
    }
  }

  &__subheader {
    font-size: 14px;
    line-height: 1.42;
    letter-spacing: .01em;
    margin-top: -.5em;
    margin-bottom: 1em;
  }

  &__icon {

  }

  &__text {
    font-family: 'Indivisible', sans-serif;
    font-weight: 500;
    letter-spacing: .01em;
    font-size: 14px;
  }

  &__entry {
    margin-bottom: calc(16 / 14) * 1em;

    &:last-child {
      margin-bottom: 0;
    }

    &__label {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2em;
      margin-top: 0;
      margin-bottom: calc(8 / 14) * 1em;
    }

    &__value {
      font-weight: 500;
      letter-spacing: .01em;
      display: flex;
      align-items: center;
      gap: 5px;

      &, > * {
        font-size: 16px !important;
        line-height: 1.2em;
      }
    }
  }
}

@media (max-width: 600px) {
  .c-transfer__row {
    flex-direction: column-reverse;

    .c-transfer__col {
      padding: 1rem;

      .field-container > input {
        width: 85vw;
      }
    }

    .c-transfer-info {
      min-width: unset;
      margin-top: -50px;
    }
  }

  .next-btn {
    margin-left: 1rem;
  }
}