@import 'src/styles/mixins';

.tab_element {
  max-height: 80px;
  display: flex;
  align-items: center;
  padding: 36px 39px;
  font-weight: 600;
  font-size: 16px;
  color: #000 !important;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  user-select: none;
  margin: 0 0;
  position: relative;

  &.active, &:hover {
    height: 100%;
    color: #000;
    border-bottom: 4px solid #07ACA6;
  }

  @include tablet {    
    font-size: 12px;
    padding: 21px 15px;
  }

  @include phone {    
    font-size: 21px;
  }
}
