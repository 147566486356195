@import 'src/styles/mixins';

.navigation {
  z-index: 0;

  .navigation_container {
    height: 100%;
    display: flex;
    align-content: stretch;

    & > div {
      display: flex;
    align-items: center;
    }
  }

  @include phone {    
    display: none;
  }
}

.c-nav-separator {
  pointer-events: none;
  width: 1px;
  height: 100%;
  position: relative;
  margin-left: -1px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';

    height: 50%;
    max-height: 30px;
    width: 1px;
    opacity: .4;
    background-color: var(--dark, #455A64);
  }

  @include phone {    
    display: none;
  }
}
