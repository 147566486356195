.c-bcu-data {
  //
}

.c-batch-data {

}

.c-batch-data-table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  .c-batch-data-table__head-cell {
    text-align: left;
  }

  .ant-table-cell {
    font-size: 14px;
  }
}

.ant-input, .ant-select-selection-item{
  color: fieldtext;
}

.ant-picker {
  .ant-picker-input {
    input {
      color: fieldtext;
    }
  }
}
