.custom-phone {
  &__code-container {
    min-width: 120px;
  }

  &__error {
    margin: 5px 0 0 5px;
    font-size: 12px;
    color: #D64751;
  }

  &__select {
    & > div {
      margin: 0;
    }

    input {
      width: 100%;
    }

    label {
      margin-top: 0;
    }
  }
}
