.transfer-contact-list-list {
    padding: 8px 0 112px 0;
    justify-content: center;
    align-items: stretch;
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 20px;
    background: #fff;
    transition: opacity .25s linear;

    &.is-loading {
      opacity: .5;
      pointer-events: none;
    }

    &_header {
        display: flex;
        height: 72px;
        padding: 0 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
    }

    &_end {
      display: flex;
      height: 72px;
      padding: 0 24px;
      justify-content: end;
      align-items: center;
      align-self: stretch;
    }
}

.contact-list-table {
  &__tr {
    // display: flex;
    padding: 8px 72px 8px 24px;
    // align-items: flex-start;
    // gap: 16px;
    width: 100%;
    background: var(--white, #FFF);

    &.even {
      background: var(--dark-table-column, #F9FBFC);
    }
  }

  &__td {
    // display: flex;
    height: 40px;
    padding: 0 12px 0 32px;
    // flex: 1 0 0;
    max-width: 228px;
    // flex-direction: column;
    // justify-content: center;
    // align-items: flex-start;
    // gap: 16px;
  }

  &__empty {
    cursor: default;
    text-align: center;
    padding: 15px 0;
    color: var(--text, #5C6D76);
    font-family: 'Indivisible', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.1px;
    white-space: nowrap;
  }

  &__text-head {
    color: var(--text, #5C6D76);
    font-family: 'Indivisible', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    letter-spacing: 0.1px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__text {
    color: var(--text, #5C6D76);
    font-family: 'Indivisible', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
    letter-spacing: 0.1px;
    white-space: nowrap;
  }
}

.pdf-link, .pdf-link * {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  color: var(--brand-color, #07ACA6);
}