.start-section {
    background-color: #064760;
    
    .start-inner {
        display: flex;
        align-items: center;
        padding: 110px 77px;

        .start-inner-title {
            font-size: 40px;
            color: #fff;
            font-weight: 700;
            margin: 0 76px 0 0;
        }

        .start-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 150px;
            margin-left: 60px;
            cursor: pointer;
            background-color: #DC7534;
            border: none;
            border-radius: 50px;
            font-size: 25px;
            font-weight: 600;
            width: 208px;
            height: 80px;
            padding: 25px 35px;

            a {
                color: #fff;
            }
        }
    }
}

@media (max-width: 768px) {
    .start-section .start-inner {
        flex-direction: column;
        padding: 50px;

        .start-btn {
            margin-top: 4rem;
            margin-left: 150px;
        }

        .start-inner-title {
            text-align: center;
            font-size: 30px;
            margin: 0;
        }
    }
}