.steps-reg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    gap: 10px;
    margin-top: 32px;
    margin-right: 32px;
    width: 444px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 20px;
  }
