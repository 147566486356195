$font-path: '../../public/fonts/Indivisible Font';

@mixin font-face($name, $file, $weight, $style) {
  @font-face {
    font-family: $name;
    src: url("#{$font-path}/#{$file}.eot");
    src: local("#{$name} #{$weight} #{$style}"), local("#{$file}"),
         url("#{$font-path}/#{$file}.eot?#iefix") format('embedded-opentype'),
         url("#{$font-path}/#{$file}.woff2") format('woff2'),
         url("#{$font-path}/#{$file}.woff") format('woff'),
         url("#{$font-path}/#{$file}.ttf") format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face('Indivisible', 'Indivisible-ThinItalic', 100, italic);
@include font-face('Indivisible', 'Indivisible-MediumItalic', 500, italic);
@include font-face('Indivisible', 'Indivisible-Black', 900, normal);
@include font-face('Indivisible', 'Indivisible-RegularItalic', normal, italic);
@include font-face('Indivisible', 'Indivisible-Bold', bold, normal);
@include font-face('Indivisible', 'Indivisible-BoldItalic', bold, italic);
@include font-face('Indivisible', 'Indivisible-BlackItalic', 900, italic);
@include font-face('Indivisible', 'Indivisible-Light', 300, normal);
@include font-face('Indivisible', 'Indivisible-SemiBoldItalic', 600, italic);
@include font-face('Indivisible', 'Indivisible-LightItalic', 300, italic);
@include font-face('Indivisible', 'Indivisible-Medium', 500, normal);
@include font-face('Indivisible', 'Indivisible-SemiBold', 600, normal);
@include font-face('Indivisible', 'Indivisible-Thin', 100, normal);
@include font-face('Indivisible', 'Indivisible-Regular', normal, normal);
