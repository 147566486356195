.form-title--reg {
  margin: 0;
  width: 500px;
  height: 24px;
  font-family: 'Indivisible', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #455A64;
  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}

.form-title--text {
  margin-left: 5px;
}

.remove_batch {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
