.admin-logs {
  padding-top: 1em;
  max-width: 95vw;
  min-width: 95vw;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &__action {
      min-width: 186px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .admin-logs__header_text {
      font-weight: 500;
      font-size: 24px;
      margin: 0;
    }
  }

  &__body {
    > .table > .table-wrapper > .base_table {
      table-layout: fixed;

      thead tr th:first-child {
        padding-left: 42px;
        width: 140px;
      }

      thead tr th:nth-child(2) {
        width: 100px;
      }

      thead tr th:nth-child(3) {
        width: 200px;
      }

      thead tr th:nth-child(4) {
        width: 120px;
      }

      thead tr th:nth-child(5) {
        width: 200px;
      }

      thead tr th:nth-child(6) {
        width: 200px;
      }

      thead tr th {
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
      }

      tbody tr td:first-child {
        padding-left: 20px;
      }

      tbody tr td {
        font-size: 14px;
        height: auto;
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
}

.c-action-log {
  &__details {
    word-wrap: break-word;
    font-size: 12px;
  }
}
