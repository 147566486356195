.summary-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 8px 0px;
    gap: 20px;
    font-family: 'Indivisible';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
   
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.selected {
    width: 490px;
}

.selected-two {
    width: 490px;
}

.summary-container.border {
    border-bottom: 1px solid rgba(69, 90, 100, 0.1);
}

