@import "src/styles/mixins";
.first {
    @include base-margin;
}

.second {
    margin-top: 27px;
}

.generalStep-info {
    margin-top: 10px;
    font-size: 12px;
    color: #9F9F9F;
}

.rad {
    margin-left: 5px;
}

.field-l {
    @include field-label;
}
.field-s {
    @include field-input;
}

.rad-1 {
    margin: 0 0 0 2px;
}

.rad-2 {
    margin-left: 22px;
}

.input {
    margin: 0 0 0 10px;
}

