.faq-wrap {
    .faq-head-wrap {
        margin-bottom: 57px;

        .faq-title {
            font-size: 40px;
            color: #000;
            margin: 0;
            text-align: center;
        }

        .faq-desc-wrap {
            width: 100%;
            display: flex;
            justify-content: center;

            .faq-desc {
                max-width: 620px;
                font-size: 16px;
                color: #000;
                margin: 0;
                text-align: center;
            }
        }
    }
    
    .faq-questions {
        border: 1px solid #ddd;
        border-radius: 5px;
        overflow: hidden;
    }
      
    .faq-item {
        border-bottom: 1px solid #ddd;
    }
      
    .faq-question {
        display: flex;
        align-items: center;
        padding: 15px;
        cursor: pointer;
        background: #f9f9f9;
        font-weight: bold;

        .faq-question-text {
            margin-left: 10px;
            font-size: 14px;
            font-weight: 400;
            color: #000;
        }
    }
      
    .faq-question:hover {
        background: #f1f1f1;
    }
      
    .faq-answer {
        padding: 15px;
        border-top: 1px dotted #ccc;
    }
      
    .faq-answer p {
        margin: 0;
        font-size: 14px;
        color: #333;
    }
      
    .faq-item:last-child {
        border-bottom: none;
    }
}   

@media (max-width: 600px) {
    .faq-wrap {
        .faq-head-wrap {
            .faq-title {
                font-size: 30px;
                margin-bottom: 1rem;
            }
        }
    }
}