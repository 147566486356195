.custom-select__wrapper {
    .ant-select {
        .ant-select-selector {
            min-height: 42px;
            border: none;
            background-color: rgba(69, 90, 100, 0.10196078);
        
            .ant-select-selection-item {
                background-color: #fff;
            }
        }

        .ant-select-selection-placeholder {
            color: #757779;
        }
    }
}