@import "src/styles/extends";

.base_submit_button {
  padding: 10px 24px;
  @extend %M3;
  cursor: pointer;
  user-select: none;
  border: 1px solid #5C6D76;
  border-radius: 100px;
  background: transparent;
  margin:  0;

  &.active {
    border: none;
    background-color: #07ACA6;
    border-radius: 100px;
    color: white;
  }

  &.loading {
    display: flex;
    padding-right: 13px;

    > .loading-icon {
      font-size: 14px;
      margin-left: 5px;
      align-self: center;
      color: inherit;
    }
  }

  &.clear_submit_button_lock {
    color: rgba(92, 109, 118, 0.5);
    background: rgba(69, 90, 100, 0.1);
  }
  &.base_color {
    background-color: #07ACA6;
    border-radius: 100px;
    color: white;
    border: none;
  }
}
