.login_screen {
  display: flex;
  min-height: calc(100vh - 128px);
  align-items: center;
  justify-content: center; 

  &__form {
    background-color: white;
    border-radius: 20px;
    padding: 40px;
    width: 580px;

    &__header {
      margin-bottom: 35.5px;
    }

    form {
      max-width: 500px;

      h1 {
        font-weight: 500;
        font-size: 24px;
      }
    }
    
    .login_buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;

      .forgot-password {
        display: flex;
        align-items: center;
        > a {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}

.label-input {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (max-width: 600px) {
  .login_screen {
    min-height: 75vh;
  }
}