.admin-submissions-note {
  margin: 24px 24px 0 24px;

  &__header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__body {
    &__limit {
      font-size: 12px;
      text-align: right;
    }
  }
}
