.user_menu {
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;

  .name {
    display: flex;
    align-items: center;
    color: #ffffff;

    > span {
      color: #ffffff;
    }

    &__company-name {
      font-size: 18px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: start;
    }

    &__avatar {
      margin-right: 15px;
      min-width: 40px;
      span {
        color: #ffffff;
      }
    }
  }
  .menu {
    display: none;
  }
  &:hover {
    .menu {
      display: block;
      position: absolute;
      top: 100%;
      right: 0;
      padding-top: 12px;
      padding-bottom: 12px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      background-color: white;

      .menu_content {
        width: 208px;
        border-radius: 5px;
        cursor: auto;

        &:hover {
          background-color: rgba(69, 90, 100, 0.1);
        }

        .base_button {
          gap: 12px;
          padding: 10px 16px;
        }
      }
    }
  }
}
