%M1 {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
}

%M1_2 {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
}

%M2_light {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
}

%M2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: left;
}

%M3 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

%M4 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
}