@import "src/styles/mixins";

.registration-dropdown {
  &__container {
    @include base-margin;
  }

  &__wrapper {
    position: relative;
  }

  &__label {
    @include field-label;
    font-size: 14px;
    min-height: 21px;
  }

  &__required {
    color: red;
  }

  &__label2 {
    position: absolute;
    margin-bottom: 10px;
    margin-left: 452px;
  }

  &__select {
    width: 100% !important;
    padding: 14px !important;
    font-weight: 500;
    @include field-input;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 14px;
    appearance: none;
    border: 1px solid #909090;
    cursor: pointer;
    height: 46.8px;

    &.active {

    }

    &.error {
      border: 1px solid #D64751;
    }
  }

  &__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 2px 6px 2px #00000026;
    position: absolute;
    left: 0;
    z-index: 997;

    span {
      margin-top: 0;
      color: #5C6D76 !important;
    }
  }

  &__option {
    padding: 8px 10px;
    font-size: 16px;
    cursor: pointer;

    &.active {
      background-color: #00000017;
    }

    &:hover {
      background-color: #0000000a;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #455A641A;
    }
  }

  &__arrow-icon {
    color: #455A64;
    font-size: 16px;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);

    &.active {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &__close-icon {
    color: #455A64;
    font-size: 15px !important;
    font-weight: 600 !important;
    position: absolute;
    top: 50%;
    right: 60px;
    transform: translateY(-50%) scaleY(1.1);
    cursor: pointer;

    &::after {
      content: '';
      width: 1px;
      height: 20px;
      background-color: hsl(0, 0%, 70%);
      position: absolute;
      top: 50%;
      right: -15px;
      transform: translateY(-50%);
    }
  }

  &__error-icon {
    color: #D64751;
    font-size: 16px !important;
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-50%);
  }

  &__error {
    margin: 5px 0 0 5px;
    color: #D64751 !important;
    font-size: 12px !important;
  }
}
