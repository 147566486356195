.custom_checkbox {
  $self: &;

  &__label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    .checkbox_icon {
      margin-right: 16px;

      &:after {
        content: 'check_box_outline_blank';
      }
    }

    #{$self}__checkbox {
      position: absolute;
      left: -999999px;

      &:checked {
        & + .checkbox_icon:after {
          content: 'check_box'
        }
      }
    }
  }

  &__error {
    margin: 5px 0 5px;
    font-size: 12px;
    color: #D64751;
  }
}

