@mixin base-margin {
  margin-top: 16px;
}
@mixin input-status {
  &:focus {
    outline: none;
    box-shadow: none;
    border: 1.5px solid #07ACA6;
  }
  &:active {
    outline: none;
    box-shadow: none;
    border: 1px solid #9F9F9F;
  }
  &:disabled {
    outline: none;
    box-shadow: none;
    border: 1px solid #9F9F9F;
    background-color: #E6E6E6;
  }
  &:invalid {
    border-color: #d64751;
  }
}

@mixin field-label {
  margin-left: 5px;
  height: 19px;
  font-family: 'Indivisible', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #455A64;
  flex: none;
  order: 0;
  flex-grow: 0;
  white-space: nowrap;
}
@mixin field-input {
  margin-top: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 14px;
  width: 500px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #909090;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  &:invalid {
    border-color: #d64751;
  }
  @include input-status;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
  }
}
@mixin font-regular($font-size) {
  font-family: 'Indivisible', serif;
  font-style: normal;
  font-weight: 500;
  font-size: $font-size;
}

@mixin button-text {
  font-family: 'Indivisible', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #FFFFFF;
  white-space: nowrap;
}

@mixin align-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin tablet {
  @media (max-width: 1200px) { @content; }
}

@mixin phone {
  @media (max-width: 600px) { @content; }
}
