@import "src/styles/mixins";

.c-file-upload__container {
  @include base-margin;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px;
  gap: 5px;
  min-height: 60px;

  margin-top: 5px;
  background: #fff;
  border: 1px dashed rgba(92, 109, 118, 0.5);
  border-radius: 8px;
  font-size: 14px;

  > * {
    color: #9F9F9F;
  }
}

.c-file-upload {
  &--error &__container {
    border-color: #ff3c38;
  }

  &__error {
    margin: 5px 0 5px;
    font-size: 12px;
    color: #D64751;
  }
}

.c-file-upload__label {
  @include field-label;
  font-size: 14px;
  min-height: 21px;

  &--required:after {
    content: '*';
    color: #ff3c38;
  }
}

.c-file-upload__button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  text-align: center;
  height: 30px;
  background: #07ACA6;
  border-radius: 100px;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #06A096;
  }

  &:active {
    background-color: #058E88;
  }
}

.c-file-upload__text {
  line-height: 120%;
  letter-spacing: 0.1px;
  height: 14px;
  font-size: 12px;
  color: #fff;
}

.c-drop-panel {
  display: flex;
  gap: 5px;

  &__file {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    color: var(--brand-color, #07ACA6);
  }

  &__remove {
    cursor: pointer;
  }
}
