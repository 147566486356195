.change-password-screen {
  display: flex;
  min-height: calc(100vh - 128px);
  align-items: center;
  justify-content: center;

  &__form {
    background-color: white;
    border-radius: 20px;
    padding: 3em;
    max-width: 570px;
    width: 570px;

    &-description {
      font-weight: 500;
      font-size: 12px;
      color: rgba(92, 109, 118, 0.5);
      padding-left: 15px;
      margin-bottom: 16px;
    }

    &-submit {
      margin-top: 40px;
    }

    h1 {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 27px;
    }
  }
}

.group-resert-password {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
