.docs-wrap {
    .docs-head-wrap {
        margin-bottom: 40px;

        .docs-title {
            font-size: 40px;
            color: #000;
            margin: 0;
            text-align: center;
        }

        .docs-desc {
            font-size: 16px;
            color: #000;
            margin: 0;
            text-align: center;
        }
    }
    .docs-links {
        .docs-link {
            margin: 0 0 7px 0;
            display: flex;
            align-items: center;
            text-decoration: underline;

            .docs-link-icon-wrap {
                width: 25px;
                margin-top: 2px;
                height: 20px;

                .docs-link-icon {
                    margin-right: 3px;
                }
            }
        }
        .no-decorations {
            text-decoration: none;
        }
    }
}   

@media (max-width: 600px) {
    .docs-wrap {
        .docs-links {
            .docs-link {
                margin-bottom: 10px;

                .docs-link-icon-wrap {
                    min-width: 25px;
                }
            }
        }
        .docs-head-wrap {
            .docs-title {
                font-size: 30px;
            }
        }
    }
}
