@import 'src/styles/mixins';

.c-table {
  padding: 8px;
  justify-content: center;
  align-items: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 20px;
  background: #fff;
  transition: opacity .25s linear;
  position: relative;
  overflow-x: hidden;

  &.is-loading {
    opacity: .5;
    pointer-events: none;
  }

  &__header {
    display: flex;
    height: 72px;
    padding: 0 24px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    @include phone {    
      margin-bottom: 21px;
      padding: 0 7px;
      justify-content: center;
      bottom: 0;
      left: 0;
      position: sticky;
    }
  }

  &__end {
    display: flex;
    height: 72px;
    padding: 0 24px;
    justify-content: end;
    align-items: center;
    align-self: stretch;
  }

  &__table {
    border-collapse: collapse;
    border: none;
    width: 100%;
  }

  &__row {
    height: 100%;
    padding: 0 12px 0 32px;
    display: flex;
    align-items: center;

    &.isPointer {
      cursor: pointer;
    }

    @include phone {    
      padding:0 6px 0 16px;
    }
  }

  &__arrow {
    position: relative;
    font-size: 9px;

    &:before {
      content: '\25B6';

      position: absolute;
      left: -2em;
      line-height: 1;
      top: 50%;
      margin-top: -.5em;
      display: inline-block;

      @include phone {
        left: -1.5em;
      }
    }
  }

  &__tr {
    border: none;
    width: 100%;
    background: var(--white, #FFF);
    border-left: 2px solid transparent;
    border-bottom: 2px solid transparent;

    .c-table__th {
      font-size: 16px;
      font-weight: 500;
      color: #5C6D76;
    }

    &.is-even {
      background: var(--dark-table-column, #F9FBFC);
    }

    @include phone {
      padding-left: 12px;
    }
  }

  &__tr:has(+ .c-table__tr--inner) &__arrow:before {
    transform: translateX(-3px) translateY(-2px) rotate(90deg);
  }

  &__tr:has(+ .c-table__tr--inner) {
    border-left-color: var(--brand-color, #07ACA6);
    height: 50px;
  }

  &__tr--inner {
    border-left-color: var(--brand-color, #07ACA6);
    position: relative;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 2px;
      width: calc(100% - 2px);
      left: 2px;
      background-color: var(--dark-table-column, #F9FBFC);
    }
  }

  &__tr--inner-last {
    border-bottom-color: var(--brand-color, #07ACA6);
    border-left-color: var(--brand-color, #07ACA6);
  }

  &__tr--header {
    .c-table__th:first-child {
      width: 15%;
    }
  }

  &__tr--header {
    .c-table__th:last-child {
      width: 10%;
    }
  }

  &__td {
    height: 56px;
    max-width: 260px;
    overflow: hidden;

    &--right {
      text-align: right;
    }
  }

  &__empty {
    cursor: default;
    text-align: center;
    padding: 15px 0;
    color: var(--text, #5C6D76);
    font-family: 'Indivisible', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.1px;
    white-space: nowrap;
  }

  &__text-head {
    color: var(--text, #5C6D76);
    font-family: 'Indivisible', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
    letter-spacing: 0.1px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__text {
    width: 100%;
    height: 100%;
    color: var(--text, #5C6D76);
    font-family: 'Indivisible', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.1px;
    //white-space: nowrap;
  }

  @include tablet {
    padding-bottom: 0;
    flex-direction: column-reverse;
    overflow: auto;

    * {
      font-size: 9px !important;
    }
  }
}
