.benefits-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 97px 104px;

    .benefits-title {
        font-size: 40px;
        color: #000;
        font-weight: 700;
        margin: 0 0 10px 0;
    }

    .benefits-description {
        color: #000;
        max-width: 940px;
        font-size: 18px;
        text-align: center;
        font-weight: 400;
        margin: 0 0 64px 0;
    }

    @media (max-width: 1200px) {
        .benefits-card-wrap {
            flex-direction: column;
        }
    }

    .benefits-card-wrap {
        display: flex;
        flex-wrap: nowrap;
        gap: 17px;

        .benefit-card {
            padding: 30px 24px;
            background-color: #fff;
            color: #000;
            border: none;
            border-radius: 10px;

            .benefit-card-title {
                font-size: 20px;
                margin: 5px 0 20px 0;
            }

            .benefit-card-desc {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: 600px) {
    .benefits-section {
        margin: 60px 30px;
        
        .benefits-title {
            font-size: 30px;
            text-align: center;
        }

        .benefits-description {
            margin-bottom: 30px;
        }
    }
}