.admin-users-info {
  margin-top: 24px;

  &__profile {
    &__header {
      font-weight: 500;
      font-size: 16px;
      padding: 20px 0;
    }
  }
}
