.intro-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #000;

    @media (max-width: 1200px) {
        .intro-inner {
            flex-direction: column;
        }    
    }

    .intro-inner-wrap {
        margin: 71px 112px 0 112px;

        .intro-inner {
            display: flex;
            text-align: center;
            line-height: normal;
    
            .intro-left {
                text-align: left;
    
                .intro-title {
                    font-size: 40px;
                    margin: 0 0 25px 0;
                }
    
                .intro-desc {
                    max-width: 600px;
                    font-size: 18px;
                    margin-right: 38px;
                    line-height: 150%;
                }
            }
    
            .intro-right {
                margin-left: 54px;
    
                .intro-image {
                    width: 516px;
                }
            }
    
        }
    }

    @media (max-width: 1200px) {
        .intro-btn-wrap {
            display: flex;
            justify-content: center;
        }
    }

    .intro-btn-wrap {
        width: 100%;
    
        .intro-btn {
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: #DC7534;
            border: none;
            border-radius: 50px;
            font-size: 25px;
            font-weight: 600;
            width: 208px;
            height: 80px;
            padding: 25px 35px;
            margin-bottom: 50px;
            
            a {
                color: #fff;
            }
        }
    }
}

@media (max-width: 768px) {
    .intro-btn {
      font-size: 16px;
    }
    .intro-section .intro-inner-wrap .intro-inner {
        .intro-left {
            .intro-title {
                font-size: 30px;
                text-align: center;
            }
            .intro-desc {
                font-size: 18px;
                margin-right: 0;
            }
        }
        .intro-right {
            margin-left: 0;
        }
    }
}

@media (max-width: 600px) {
    .intro-section .intro-inner-wrap .intro-inner .intro-right .intro-image {
        width: 400px;
    }    
}

@media (max-width: 400px) {
    .intro-section .intro-inner-wrap .intro-inner .intro-right .intro-image {
        width: 300px;
    }
}
