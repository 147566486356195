.c-badge {
  width: 24px;
  height: 24px;
  background-color: var(--primary, #07aca6);
  text-align: center;
  border-radius: 50%;
  line-height: 24px;
  margin-left: 10px;
  margin-right: -24px;
  position: absolute;
  right: 25px;
  top: 15px;

  &__number {
    font-size: 14px;
    color: var(--primary-inverse, #fff);
  }
}