.confirmation {
  &__title {
    & > div {
      color: #07ACA6;
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.centralized {
  .confirmation__title {
    display: flex;
    justify-content: center;
  }

  .modal-body {
    text-align: center;
  }

  .modal-footer {
    justify-content: center;
  }
}

