.list {
    &__header {
        width: 100%;
        margin-bottom: 12px;
        padding: 0 20px;
        display: flex;
        align-items: center;
    
        & > div {
          text-align: left;
          word-break: break-all;
          font-weight: 500;
          padding: 0 10px;
        }
      }
    
    &__item {
        //height: 56px;
        font-size: 14px;
        padding: 15px 20px;
        display: flex;
        align-items: center;

        & > div {
            padding: 0 10px;
            word-break: break-all;
        }

        &.odd {
            background-color: #F9FBFC;
        }
    }

    &__noResult {
        margin-top: 36px;
        text-align: center;
    }
}
