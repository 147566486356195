.editable-table {

  &__submit {
    padding: 0;
    border: none;
    margin: 0;
  }

  tr {
    td, th {
      white-space: nowrap;
    }

    // td:last-child {
    //   position: sticky;
    //   right: 0;
    //   background-color: inherit;
    //   vertical-align: middle;
    //   padding-right: 20px;
    //   padding-left: 20px;
    //   box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    // }
  }

}
