.c-information {
  width: 100%;

  &__header {
    font-weight: 400;
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    margin-bottom: 16px;
  }
}

.c-information-content {
  justify-content: center;
  align-items: stretch;
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 20px;
  background: #fff;
  transition: opacity .25s linear;
  position: relative;
  overflow-x: hidden;
  padding: 15px 25px 25px 25px;
}

.c-information-content {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,rgba(6,147,227,1) 0%,rgb(155,81,224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,rgb(122,220,180) 0%,rgb(0,208,130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,rgba(252,185,0,1) 0%,rgba(255,105,0,1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,rgba(255,105,0,1) 0%,rgb(207,46,46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,rgb(238,238,238) 0%,rgb(169,184,195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,rgb(74,234,220) 0%,rgb(151,120,209) 20%,rgb(207,42,186) 40%,rgb(238,44,130) 60%,rgb(251,105,98) 80%,rgb(254,248,76) 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,rgb(255,206,236) 0%,rgb(152,150,240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,rgb(254,205,165) 0%,rgb(254,45,45) 50%,rgb(107,0,62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,rgb(255,203,112) 0%,rgb(199,81,192) 50%,rgb(65,88,208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg,rgb(255,245,203) 0%,rgb(182,227,212) 50%,rgb(51,167,181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg,rgb(202,248,128) 0%,rgb(113,206,126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg,rgb(2,3,129) 0%,rgb(40,116,252) 100%);
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);

  --underline-intrinsic-width: 8;
  --underline-width: 20;
  --underline-cap-width: 1px;
  --underline-offset-y: 0px;
  --underline-padding-x: 0.4em;

  font-size: 18px;
  line-height: 1.167;

  &__container {
    width: 100%;
  }

  .has-text-align-left {
    text-align: left;
  }

  .has-text-align-right {
    text-align: right;
  }

  .has-text-align-center {
    text-align: center;
  }

  .underline-text, h1.wp-block-heading {
    margin: 0;
    color: #000;
    display: inline !important;

    --underline-width-scale: calc(var(--underline-width) / var(--underline-intrinsic-width));
    padding: 0 calc(var(--underline-padding-x) + calc(var(--underline-cap-width) * var(--underline-width-scale))) 0 0;
    box-decoration-break: clone;
    background-repeat: no-repeat;
    background-image: linear-gradient(180deg, rgba(76, 155, 148, 0.6), rgba(76, 155, 148, 0.6));
    background-position-x: calc(var(--underline-cap-width) * var(--underline-width-scale)), 0, 100%;
    background-position-y: calc(100% - var(--underline-offset-y) * -1);
    background-size: calc(100% - calc(var(--underline-cap-width) * var(--underline-width-scale) * 2)) calc(var(--underline-width) * 1px), auto calc(var(--underline-width) * 1px), auto calc(var(--underline-width) * 1px);
  }

  h1.wp-block-heading + * {
    margin-top: 20px;
  }

  .has-large-font-size {
    font-size: var(--wp--preset--font-size--large) !important;
  }

  a {
    transition: 0.3s;
    text-decoration: underline;

    &, > * {
      color: #39f;
    }
  }

  .wp-container-2.wp-container-2, .wp-container-3.wp-container-3, .wp-container-5.wp-container-5 {
    flex-wrap: nowrap;
  }

  .wp-block-separator {
    border: 1px solid;
    border-left: none;
    border-right: none;
    width: 100%;
  }

  hr {
    box-sizing: content-box;
    height: 0;
  }

  .has-cyan-bluish-gray-color {
    color: #abb8c3 !important;
  }

  .has-cyan-bluish-gray-background-color {
    background-color: #abb8c3 !important;
  }

  .is-nowrap {
    flex-wrap: nowrap !important;
  }

  .is-layout-flex > * {
    margin: 0;
  }

  figure {
    margin: 1em 40px;
  }

  .wp-block-columns {
    align-items: normal!important;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap!important;
  }

  .wp-block-column {
    flex-grow: 1;
    min-width: 0;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  :where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
  }

  .wp-block-columns {
    align-items: normal!important;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap!important
  }

  @media (min-width: 782px) {
    .wp-block-columns {
      flex-wrap:nowrap!important
    }
  }

  .wp-block-columns.are-vertically-aligned-top {
    align-items: flex-start
  }

  .wp-block-columns.are-vertically-aligned-center {
    align-items: center
  }

  .wp-block-columns.are-vertically-aligned-bottom {
    align-items: flex-end
  }

  @media (max-width: 781px) {
    .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
      flex-basis:100%!important
    }
  }

  @media (min-width: 782px) {
    .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
      flex-basis:0;
      flex-grow: 1
    }

    .wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column[style*=flex-basis] {
      flex-grow: 0
    }
  }

  .wp-block-columns.is-not-stacked-on-mobile {
    flex-wrap: nowrap!important
  }

  .wp-block-columns.is-not-stacked-on-mobile>.wp-block-column {
    flex-basis: 0;
    flex-grow: 1
  }

  .wp-block-columns.is-not-stacked-on-mobile>.wp-block-column[style*=flex-basis] {
    flex-grow: 0
  }

  :where(.wp-block-columns) {
    margin-bottom: 1.75em
  }

  :where(.wp-block-columns.has-background) {
    padding: 1.25em 2.375em
  }

  .wp-block-column {
    flex-grow: 1;
    min-width: 0;
    overflow-wrap: break-word;
    word-break: break-word
  }

  .wp-block-column.is-vertically-aligned-top {
    align-self: flex-start
  }

  .wp-block-column.is-vertically-aligned-center {
    align-self: center
  }

  .wp-block-column.is-vertically-aligned-bottom {
    align-self: flex-end
  }

  .wp-block-column.is-vertically-aligned-bottom,.wp-block-column.is-vertically-aligned-center,.wp-block-column.is-vertically-aligned-top {
    width: 100%
  }

  .wp-block-image img {
    box-sizing: border-box;
    height: auto;
    max-width: 100%;
    vertical-align: bottom;
  }

  .wp-block-image figcaption {
    margin-bottom: 1em;
    margin-top: 0.5em;
  }

  p {
    margin: 0 0 1em;
  }

  .wp-container-1.wp-container-1 {
    justify-content: center;
  }

  .wp-block-buttons>.wp-block-button {
    display: inline-block;
    margin: 0;
  }

  .wp-block-button__link {
    color: #fff;
    background-color: #32373c;
    border-radius: 9999px;
    box-shadow: none;
    text-decoration: none;
    padding: calc(0.667em + 2px) calc(1.333em + 2px);
    font-size: 1.125em;
  }

  .is-layout-flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  :where(.is-layout-flex) {
    gap: 0.5em;
  }
}