@import 'src/styles/mixins';

.main-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > div.container {
    min-width: 78vw;
    max-width: fit-content;
    margin-top: 196px;
    padding-bottom: 32px;
    flex: 1 0 auto;

    @include tablet {
      margin-top: 125px;
    }

    @include phone {
      margin-top: 148px;
    }
  }

  & > div.container:has(.c-information) {
    margin: 176px 0 0 0;
    max-width: 100vw;
    padding: 0;
  }

  @media (max-width: 1200px) {
    & > div.container {
      margin: 196px 0 0 0;
    }
    & > div.container:has(.c-information) {
      margin: 161px 0 0 0;
    }
  }

  @media (max-width: 685px) {
    & > div.container:has(.c-information) {
      margin: 161px 0 0 0;
    }
  }

  & > div.container:has(.resources-wrap) {
    margin: 178px 0 0 0;
    max-width: 100vw;
    padding: 0;
  }

  @media (max-width: 600px) {
    & > div.container:has(.resources-wrap) {
      margin: 210px 0 0 0;
    }
  }

  @media (max-width: 600px) {
    & > div.container {
      margin: 225px 0 0 0;
    }
  } 
}
