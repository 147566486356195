.note {
  margin-top: 32px;

  &__empty {
    padding: 16px;
    font-size: 14px;
    color: #455a64;
    background-color: #f6f7f8;
    border-radius: 5px;
  }

  &__header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
  }

  &__limit {
    margin-top: 16px;
    font-size: 12px;
    text-align: right;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__edit-buttons {
    width: 100%;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-right: 0 !important;
      margin-left: 0;
      margin-top: 0;
    }

    &.isEditButton {
      justify-content: flex-end;
    }
  }

  &__text {
    white-space: pre-wrap;
  }

  textarea {
    padding: 16px;
    font-size: 14px;
    color: #455a64;
    background-color: #f6f7f8;

    &:focus,
    &:active {
      outline: 1px solid #07aca6;
    }
  }
}
