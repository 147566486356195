@import "src/styles/mixins";

.pdf-uploader--container {
  @include base-margin;
  .pdf-uploader--label {
    @include field-label;

    font-size: 14px;
  }
}
.pdf-uploader--border {
  margin-top: 5px;
  background: #FFFFFF;
  border: 1px solid #909090;
  border-radius: 8px;
  padding: 0;
  &.error {
    color: #455A64;
    border: 1px solid #d64751;
  }
}
.pdf-uploader--required{
  color: #D64751;;
}
.field-errorUp {
  color: #D64751;;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;

}
.pdf-uploader--field {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  gap: 5px;
  width: 500px;
}

.drag-drop-label {
  width: 60px;
  height: 14px;
  font-family: 'Indivisible';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  color: #FFFFFF;
}

.drag-drop-text {
  width: 159px;
  height: 17px;
  font-family: 'Indivisible';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: #9F9F9F;
  white-space: nowrap;
}

.drop-panel {
  display: flex;
  justify-content: space-between;
  width: 500px;
}

.drop-p {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  font-size: 14px;
  color: var(--brand-color, #07ACA6);
}

.close_remove_pdf {
  cursor: pointer;
}