.table {
  background: #ffffff;
  border-radius: 20px;
  position: relative;

  .table-pagination {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }

  .table-wrapper {

    .ant-table {
      border-radius: 20px;

      .ant-table-thead {
        .ant-table-cell {
          border-radius: 20px 20px 0 0;
        } 
      }
    }
    .table-service-information {
      text-align: center;
      padding: 2em;
      user-select: none;

      &__loading {
        display: flex;
        justify-content: center;
        align-items: center;

        > .loading-icon {
          margin-right: 10px;
          color: #07aca6;
          font-size: 2em;
        }
      }
    }
  }

  &__editButton {
    padding: 0 !important;
    position: absolute;
    right: 0;
    cursor: pointer;

    &_container {
      width: 56px;
      height: 56px;
      padding: 17px 20px 17px 20px;
      background-color: #f9fbfc;
      position: relative;
      overflow-y: clip;

      &.even {
        background-color: #fff;
      }

      &::after {
        content: '';
        width: 100%;
        height: 110%;
        box-shadow: 0px 0px 4px 0px #00000040;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    span {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 101;
      top: 50%;
      left: 50%;
      color: #5c6d7680;
      z-index: 101;
      transform: translate(-50%, -50%);
      transition: color 0.2s;

      &.isDone {
        color: #0aaf60;

        &:hover {
          color: darken(#0aaf60, 5%);
        }
      }

      &.disabled {
        pointer-events: none;
        color: #5c6d7680;
      }

      &:hover {
        color: darken(#5c6d7680, 50%);
      }
    }
  }
}

.admin-logs__body {
  .ant-table-cell {
    max-width: 200px;
  }
}

.active-header {
  color: #07aca6;
}
