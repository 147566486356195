.price-wrap {
    .price-head-wrap {
        margin-bottom: 47px;

        .price-title {
            font-size: 40px;
            color: #000;
            margin: 0;
            text-align: center;
        }

        .price-desc {
            font-size: 16px;
            color: #000;
            margin: 0;
            text-align: center;
        }
    }
    
    .price-content {
        font-size: 16px;
        color: #000;
        margin: 0;

        p {
            margin: 0;
        }

        .price-link {
            text-decoration: underline;
            color: #000;
        }
    }
}   

@media (max-width: 600px) {
    .price-wrap {
        .price-head-wrap {
            margin-bottom: 30px;
            
            .price-title {
                font-size: 30px;
                margin-bottom: 1rem;
            }
        }
    }
}
