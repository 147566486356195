.email-verification {
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 134px);

  &__text {
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
    width: 700px;

    &.email {
      font-weight: 500;
    }

    a {
      padding: 0 0 0 4px;
      width: fit-content;
      color: #07ACA6;
    }

    h1 {
      font-weight: 500;
      width: fit-content;
    }
  }
  img {
    width: 112px;
    height: 220px;
    padding: 0;
  }
}
