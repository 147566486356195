@import "src/styles/colors";

.table-list {
    &__row {
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        font-size: 14px;
    }

    &__underlight {
        background-color: $ROW_COLOR;
    }

    &__input {
        width: 400px;
        display: flex;
        flex-direction: column;

        &.error {
            input {
                border: 1px solid #D64751;
            }
        }

        & > input {
            font-weight: 500;
            min-height: 44px;
            padding: 8px;
            margin-top: 0;
            background-color: transparent;
            border: 1px solid #5C6D7680;
            outline: none;
            border-radius: 8px;
            font-size: inherit;

            &:focus {
                border: 1px solid #07ACA6;
            }
        }

        span {
            margin-top: 5px;
            color: #D64751;
            font-size: 12px;
        }
    }

    &__phone {
        .custom-select {
            & span + div {
                background-color: transparent;
            }
        }

        .custom-input__wrapper {
            & > input[type=tel] {
                background-color: transparent;
            }
        }
    }

    &__buttons {
        display: flex;
        width: 100%;
        align-items: end;
        justify-content: flex-end;
        padding: 15px 15px 0;
        gap: 10px;

        > button {
            margin: 0 !important;
        }
    }
}
