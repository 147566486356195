.c-reference-values {
  min-width: 95vw;
  padding-top: 1em;
  min-width: 95vw;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__header-text {
    font-weight: 500;
    font-size: 24px;
    margin: 0;
  }
}

.certificationForm {
  max-width: 680px;
  margin: auto;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__select {
    border: 1px solid #909090;
    border-radius: 6px;

    & > div {
      background-color: #ffffff;
    }
  }

  form {
    width: 100%;

    & > div {
      margin-bottom: 24px;
      display: flex;

      & > div,
      & > label {
        width: 50%;
        margin-bottom: 0 !important;
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      margin: 0;
    }
  }
}

.certification-action {
  margin-left: 15px;
}

.reference-values-input {
  width: 100%;
  height: 100%;
}
