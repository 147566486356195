@import "src/styles/mixins";
.align-right {
    text-align: right;
  }

.field-s-container {
   display: flex;
   align-items: center;
   color:  #9F9F9F;

}

.date-input {
    align-items: center;
    margin-top: 5px;
    gap: 16px;
    width: 500px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid rgba(92, 109, 118, 0.5);
    border-radius: 8px;
  }

.first {
    margin-top: 16px;
}

.first-1 {
    margin-top: 35px;
}

.field-l {
 @include field-label;
}
.field-s {
    @include field-input;
}

.input {
    margin: 0 0 0 10px;
}

.fieldLoad {
    margin-top: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    gap: 5px;
    width: 500px;
    height: 58px;
    background: #FFFFFF;
    border: 1px dashed rgba(92, 109, 118, 0.5);
    border-radius: 8px;
}

.batch-form--wrapper {
  margin-top: 1em;
}
.batch-form--btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.batch-fields--wrapper {
  margin-top: 1em;
}
