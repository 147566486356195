.tabbed-page {
  &-wrapper {
    background-color: #FFFFFF;
    border-radius: 20px;
    //border-bottom-left-radius: 20px;
    //border-bottom-right-radius: 20px;
  }

  &__info {
    padding: 2em 0;
    h1 {
      font-weight: 500;
      font-size: 24px;
      margin-bottom: 0;
    }
  }
}
