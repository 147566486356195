@import "src/styles/mixins";

.first {
    @include base-margin;
}

.first-2 {
    margin-top: 14.5px;
}

.field-l {
    @include field-label;
}
.field-s {
    @include field-input;
}

.field-cp {
    display: flex;
    flex-direction: row;
}

.checkbox {
    margin: 0;
    height: 15px;
    width: 15px;
    left: 2.55px;
    top: 2.5px;
    border-radius: 0;

}
.checkbox-label {
    margin-left: 6.5px;
}

.subtitle {
    margin-top: 1.5rem;
    font-size: 18px;
}

.final-production {
    .address-selection-wrap {
        display: flex;
        flex-direction: column;
        margin: 1rem 0;

        .rad-1 {
            margin: 0.5rem 0;
        }
    }

    .subtitle-text {
        margin-left: 5px;
    }
}