.forgot-password-screen {
  display: flex;
  min-height: calc(100vh - 128px);
  align-items: center;
  justify-content: center;

  &__form {
    background-color: white;
    border-radius: 20px;
    padding: 3em;
    max-width: 570px;

    &-description {
      font-weight: 500;
      font-size: 16px;
      color: #5C6D76;
      margin-top: 16px;
      margin-bottom: 28px;
    }

    &-submit {
      margin-top: 40px;
    }

    h1 {
      font-weight: 500;
      font-size: 24px;
    }
  }
}
