@import 'src/styles/mixins';
@import "src/styles/extends";

.base_button_link {
  max-height: 40px;
}

.base_button {
  max-height: 40px;
  padding: 10px 24px;
  @extend %M3;
  cursor: pointer;
  user-select: none;
  border: none;
  background-color: transparent;

  &.active {
    background-color: #07ACA6;
    border-radius: 100px;
    color: white;

    a {
      color: white;
    }
  }

  @include phone {    
    padding: 8px 18px;
    font-size: 12px;
  }
}
