.custom-table { 
  .ant-table {
    border-radius: 20px!important;
  }
}

.generic-table_header {
  display: flex;
  align-items: center;
  min-height: 20px;
  justify-content: space-between;


  .generic-table_left {
    display: flex;
    align-items: center;
    width: 100%;

    .generic-table_title {
      width: 108px;
      font-size: 16px;
      font-weight: 500;
      margin-right: 1rem;
    }
  }
}
