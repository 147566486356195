.company-info {
  &__header-block {
    padding: 0 20px;
    margin-bottom: 10px;
    justify-content: space-between;

    & &__total-balance {
      background: rgba(69, 90, 100, 0.1);
      border-radius: 100px;
      height: fit-content;
      max-width: 245px;

      .view-element {
        display: flex;
        align-items: center;

        > &__label {
          color: #5C6D76;
          font-size: 14px;
        }

        > &__description {
          color: #455A64;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    h1 {
      margin-top: 0;
    }

    > &__company .view-element {
      > &__label {
        padding-left: 0;
      }
    }
  }

  &__info-block {
    padding-left: 2px !important;
  }
}
