.c-data-table {
  margin-left: 12px;
  margin-right: 12px;

  &__cell-wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    border-bottom: 1px solid #f0f0f0;
  }

  &__delimiter, &__cell {
    display: flex;
    align-items: center;
    min-height: 56px;
    font-size: 14px;
    padding: 0 12px;
    position: relative;
  }

  // TODO: this smells, should fix input component classes
  &__cell > .custom-input,
  &__input {
    width: 100%;
  }

  &__cell {
    display: flex;
  }

  &__input {
    //align-self: stretch;
    min-height: 46px;

    > .ant-select-selector {
      min-height: 46px;
    }

    &--no-controls {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button,
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &,
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  &__delimiter {
    font-weight: 600;
    grid-column: span 2;
  }
}