.download-file-button {
  display: flex;
  justify-content: space-around;
  height: 40px;
  white-space: nowrap;

  padding: 10px 33px 10px 20px;

  > span {
    margin-right: 5px;
    font-size: 20px;
    color: #FFFFFF;
  }
}
