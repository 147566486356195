.batch-form--sum1 {
    margin-top: 29px;
    width: 100%;
}

.batch-form--title {
    margin: 0 0 10px 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;

font-style: normal;

}
