@import "src/styles/mixins";
.field-l {
    margin-left: 5px;
    width: 129px;
    height: 19px;
    font-family: 'Indivisible', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #455A64;
    flex: none;
    order: 0;
    flex-grow: 0;
    white-space:
     nowrap;
}

.date-form {
    flex: 1;
    
    margin-right: 16px;
}

.date-input {
    align-items: center;
    margin-top: 5px;
    gap: 16px;
    width: 500px;
    height: 45px;
    background: #FFFFFF;
    border: 1px solid rgba(92, 109, 118, 0.5);
    border-radius: 8px;
  }

.first {
    @include base-margin;
}

.first-1 {
    margin-top: 35px;
}

.field-l {
    margin-left: 5px;

    height: 19px;
    font-family: 'Indivisible', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #455A64;
    flex: none;
    order: 0;
    flex-grow: 0;
    white-space: nowrap;
}
.field-s {
    margin-top: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 14px;
    width: 500px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(92, 109, 118, 0.5);
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
