@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

@import 'extends';
@import 'colors';

*,
::after,
::before {
  box-sizing: border-box;
}

body,
html {
  -webkit-font-smoothing: antialiased;

  font-family: 'Indivisible', sans-serif;
  font-weight: 500;
  color: $TEXT_COLOR;

  padding: 0;
  margin: 0;
  min-height: 100vh;

  // overflow-y: scroll;

  #root,
  #App {
    min-height: 100vh;
  }
}

body {
  background: #f3f5f5;
}

a,
a:hover,
a:visited {
  font-family: 'Indivisible', sans-serif;
  color: $TEXT_COLOR;
  user-select: auto;
  text-decoration: none;
}

input,
textarea,
button {
  font-family: inherit;
}

p {
  margin: 8px 0;
}

.link {
  display: flex;
  @extend %M3;
  height: 40px;
  align-items: center;
  padding: 0 10px;

  &.active {
    color: #07aca6;
    text-decoration: underline;
  }
}

.loading-icon {
  animation: spin 4s linear infinite;
  @-moz-keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
}

textarea {
  background: rgba(69, 90, 100, 0.1);
  border: none;
  border-radius: 5px;
  width: 100%;
  resize: none;
  padding: 5px;
}

.action-buttons {
  display: flex;
  justify-content: right;

  &.centered {
    justify-content: center;
  }
}

.view-elements-with-rows {
  .row {
    padding: 20px 0;

    &:nth-child(2n + 1) {
      background: rgba(69, 90, 100, 0.1);
    }

    > .view-element__description {
      display: flex;
      justify-content: right;
    }
  }
}

.error {
  font-size: 14px;
  color: #d64751;
  padding: 0 0 5px 0;
}

.c-input-radio,
.c-input-checkbox {
  margin-right: 15px;
  font-size: 14px;

  &__input {
    accent-color: #0c7a6c;
  }

  &__text {
    padding-left: 8px;
    padding-right: 5px;
  }
}

.is-icon-error,
.is-error {
  color: var(--danger, #ff3d00);
}

.is-icon-success,
.is-success {
  color: var(--positive, #0aaf60);
}

.is-icon-primary,
.is-primary {
  color: var(--primary, #07aca6);
}

.is-icon-warning,
.is-warning {
  color: var(--warning, #efc900);
}

.c-input {
  border: 1px solid #909090;

  &:focus,
  &:active {
    border: 1px solid var(--primary, #07aca6);
    outline: 1px solid var(--primary, #07aca6);
  }
}
