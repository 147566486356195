.password-input {
  & ~ &__password-visibility {
    color: rgba(92, 109, 118, 0.5);
    cursor: pointer;
    user-select: none;
  }
}
// .materiasl-icons-password {
//   width: 24px;
//   border-radius: 5px;
//   display: block;
//   background: transparent;
//   margin-right: 5px;
// }

.password-input__password-visibility{
  width: 24px;
  margin-right: 5px;
}