.resources-wrap {
    margin: 38px 36px 46px 36px;
    background-color: #fff;
    border-radius: 20px;

    .resources {
        display: flex;
        min-height: 100vh;

        .resources-sidebar {
            width: 20%;
            margin-top: 65px;
            margin-left: 45px;
            display: flex;
            flex-direction: column;

            .faq-subroutes {
                display: flex;
                flex-direction: column;
            }

            .resources-link {
                display: flex;
                justify-content: space-between;
                cursor: pointer;
                margin-bottom: 35px;
                font-size: 16px;
                font-weight: 400;
                text-decoration: none;
                color: black;
                transition: color 0.2s ease;


                &.active {
                    color: #0c9992;
                }
            }
        }

        .resources-content {
            width: 80%;
            margin-top: 22px;
            padding: 2rem;
        }
    }
}

@media (max-width: 600px) {
    .resources-wrap {
        .resources {
            flex-direction: column;

            .resources-sidebar {
                width: 75%;
                margin-top: 50px;
            }
    
            .resources-content {
                width: 100%;
                margin-top: 0;
                padding-top: 0.5rem;
            }
        }
    }
}
