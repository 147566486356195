.custom-select {
  height: 100%;
  border-radius: 8px;

  &__error-input {
    border: 1px solid #D64751;
  }

  &__wrapper {
    width: 100%;

    div {
      font-size: 14px;
    }
  }

  margin: 0;
  border-radius: 5px;

  &_lock {
    background: rgba(69, 90, 100, 0.3);
  }

  &__error {
    font-size: 12px !important;
    color: #D64751;
    padding: 0 0 5px 0;
    text-align: left;
  }

  &__description {
    font-size: 12px;
    color: rgba(92, 109, 118, 0.5);
    padding: 0 0 5px 0;
    text-align: left;
  }

  &__label {
    margin: 0 0 5px 5px;
    display: flex;
    align-items: center;
  }
}
