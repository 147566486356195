.user-info {
  .field-container {
    width: 100%;
  }

  &__field {
    display: flex;
    align-items: flex-end;
    gap: 30px;
    padding: 0 20px;
    align-self: stretch;

    input {
      width: 100%;
    }
  }

  .user-info__table {
    margin-top: 1rem;

    .ant-table-cell {
      padding: 20px;
      font-size: 14px;
    }
  }
}
