@import 'src/styles/extends';

.users {
  padding-top: 1em;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .users__header_text {
      font-weight: 500;
      font-size: 24px;
      margin: 0;
    }

    .users__header_filter {
      width: 232px;
    }

    .controls {
      display: flex;
      align-items: center;

      & > :not(:last-child) {
        margin-right: 10px;
      }

      .search {
        user-select: none;

        &__input {
          min-width: 276px;
          padding-left: 0;
          outline: none;
        }

        &__icon {
          user-select: none;
        }
      }
    }
  }

  &__table {
    &__contact-name {
      width: 400px;
    }

    & &__email {
      width: 220px;
    }

    & &__phone {
      flex-wrap: nowrap;
      width: 340px;
    }

    & &__position {
      width: 220px;
    }
  }
}
