.summary {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 29.5px 40px 24px 40px;
    margin-top: 32px;

    width: 100%;

    background: #FFFFFF;
    border-radius: 20px;
}

.summary-text {
    margin: 0;
    font-family: 'Indivisible';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
}

.btn2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }