.header .main-header__company-name {
  .feedback-container {
    position: absolute;
    top: 30px;
    right: 365px;
    cursor: pointer;
    z-index: 1000;
    color: #07aca6;
    display: flex;
    justify-content: flex-end;
    border-radius: 1rem 1rem 1rem 0;
    box-shadow: 0 0 0 1px #0000000d, 0 1px 8px #00000026;
    width: 42px;
    background: white;

    .icon-wrap {
      cursor: pointer;
      font-size: 32px;
      position: relative;
      color: #07aca6;
      z-index: 2;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }

      .material-icons-outlined {
        position: relative;
        z-index: 1;
      }

      .inner-icon {
        font-family: "Indivisible", sans-serif;
        font-size: 12px;
        font-weight: 500;
        position: absolute;
        color: #07aca6;
        right: 38%;
        top: 24%;
        z-index: 1;
      }
    }

    .drag-handle {
      position: absolute;
      bottom: 4px;
      left: 4px;
      display: grid;
      grid-template-columns: repeat(2, 3px);
      gap: 1px;

      .drag-dot {
        width: 2px;
        height: 2px;
        background-color: #07aca6;
        border-radius: 50%;
      }
    }
  }
}

.feedback {
  cursor: pointer;
  color: #FFFFFF;
  font-weight: 300;
  font-size: 16px;
  margin-right: 24px;
}

@media (max-width: 600px) {
  .feedback {
    font-size: 14px;
  }
}
