.hero-section {
    height: 550px;
    background-color: #07ACA6;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .hero-inner {
        margin: 154px 130px;
        color: #fff;
        text-align: center;
        line-height: normal;

        .hero-inner-title {
            font-size: 61px;
            letter-spacing: 0;
            margin: 0;
        }

        .hero-inner-description {
            font-size: 25px;
        }

        @media (max-width: 850px) {
            .hero-inner-title {
                font-size: 40px;
            }

            .hero-inner-description {
                font-size: 18px;
            }
        }
    }

    @media (max-width: 600px) {
        .hero-inner {
            margin: 154px 30px;
        }
    }
}

@media (max-width: 600px) {
    .hero-section {
        height: 600px;
    }
}

@media (max-width: 550px) {
    .hero-section {
        height: 700px;
    }
}
