.form-reg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 40px;
    gap: 30px;
  
    width: 580px;
    margin-top: 32px;
    
  
    background: #FFFFFF;
    border-radius: 20px;
  }
  .header-form {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 10px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Indivisible';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
  
    height: 40px;
    width: 500px;
    left: 40px;
    top: 24px;
  }
  