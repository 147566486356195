@import "src/styles/extends";

.custom-input {
  $self: &;

  &__wrapper {
    padding: 0.1px 0;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;

    &.error {
      input {
        border-color: #D64751;
      }
    }

    &.dateError {
      #{ $self }__error-icon {
        right: 40px;
      }
    }

    input {
      border-radius: 8px;
      flex: 1;
      display: block;
      width: 100%;
      padding: 14px;
      outline: none;
      background-color: transparent;
      border: 1px solid #909090;
      min-height: 41px;
      @extend %M2_light;

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
      }

      &:focus {
        border: 1px solid #07ACA6 !important;
      }

      &.date {
        cursor: pointer;
      }

      &.base_color {
        background-color: #07ACA6;
      }

      &.clear_input_lock {
        background: rgba(69, 90, 100, 0.3);
        user-select: none;
      }

      &.iconBefore {
        padding-left: 48px !important;
      }

      &:disabled {
        background-color: #E6E6E6;
        border: 1px solid #9F9F9F;
      }
    }

    input[type=date]::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .search__icon  {
      position: absolute;
      padding-left: 12px;
    }
  }

  &__description {
    color: rgba(92, 109, 118, 0.5);
    font-weight: 500;
    font-size: 12px;
    padding-top: 5px;
    padding-left: 5px;
  }

  &__error {
    margin: 5px 0 0 5px;
    font-size: 12px;
    color: #D64751;
    padding: 0 0 5px 0;
    text-align: left;
    border-color: #D64751;
  }

  &__error-icon {
    padding: 0 !important;
    color: #D64751;
    font-size: 16px !important;
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);

    &.isClearable {
      right: 40px;
    }
  }

  &__reveal-icon {
    padding: 0 !important;
    font-size: 20px !important;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);

    &.isClearable {
      right: 40px;
    }
  }

  &__close-icon {
    font-size: 16px !important;
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.label-input {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__text {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }

  &__text-required {
    color: #ff3c38;
  }
}