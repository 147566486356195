@import "src/styles/mixins";
.self-form--container{
    width: 100%;
}
.first {
    @include base-margin;
}

.first-1 {
    margin-top: 35px;
}

.field-l {
    @include field-label;
}
.field-s {
    @include field-input;
}

.field-cp {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.checkbox {
    margin: 0;
    height: 15px;
    width: 15px;
    left: 2.55px;
    top: 2.5px;
    border-radius: 0;

}
.checkbox-label {
    margin-left: 6.5px;
}
.self-form--btn {
    margin-top: 2em;
    display: flex;
    justify-content: flex-end;
}
