.demo-section {
    background-color: #1C9992;
    
    .demo-inner {
        display: flex;
        align-items: center;
        padding: 110px 77px;

        .demo-inner-title {
            font-size: 40px;
            max-width: 580px;
            color: #fff;
            font-weight: 700;
            margin: 0 76px 0 0;
        }

        .orange-line {
            display: block;
            background-color: #E97F23;
            width: 285px;
            height: 3px;
        }

        .demo-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 150px;
            margin-left: 160px;
            cursor: pointer;
            background-color: #DC7534;
            border: none;
            border-radius: 50px;
            font-size: 25px;
            font-weight: 600;
            width: 250px;
            height: 80px;
            padding: 25px 26px;

            a {
                color: #fff;
            }
        }
    }
}


@media (max-width: 768px) {
    .demo-section .demo-inner {
        flex-direction: column;

        .demo-btn {
            margin-top: 4rem;
        }
    }
}

@media (max-width: 768px) {
    .demo-section {
        .demo-inner {
            padding: 50px;

            .demo-inner-title {
                font-size: 30px;
                margin-right: 0;
            }
        }
    }
}
