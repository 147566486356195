.contact-list-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
    justify-content: center;
    width: 100%;

    border-radius: 20px;
    background: #FFF;
}

.contact-list-search {
    margin-bottom: 15px;
}

.contact-list-table {
    width: 100%;
    border-radius: 20px;
    padding: 0 20px 5px;

    input {
        font-weight: 500;
    }
}

.contact-list-token {
    display: flex;
    width: 100%;
    padding: 24px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &_header {
        color: var(--dark, #455A64);
        font-family: 'Indivisible', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; 
      }
    &_body {
        display: flex;
        align-items: flex-end;
        gap: 20px;
        align-self: stretch;
        width: 100%;
        font-weight: 500;

        input {
            font-weight: 500;
        }
    }
}