.c-incoming-transactions {
  &__actions {
    button {
      padding: 0;
      border: none;
      font-size: '1em';
      background-color: transparent;
      border-radius: 100px;
      cursor: pointer;

      &:first-child {
        margin-right: 10px;
        color: #07ACA6;
      }

      &:last-child {
        color: #D64751;
      }
    }
  }
}

.search-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-right: 1rem;
  align-items: center;

  .incoming-title {
    color: var(--dark, #455A64);
    font-family: 'Indivisible', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-left: 1rem;
  }
}

.ant-spin-nested-loading {
  .ant-spin-container {
    .ant-pagination {
      margin-right: 1rem;
    }
  }
}
