.started-section {
    display: flex;
    justify-content: center;
    background-color: #fff;
    
    .started-inner {
        margin: 56px 106px;

        .started-inner-title {
            font-size: 40px;
            color: #000;
            font-weight: 700;
            text-align: center;
            margin: 0 0 25px 0;
        }

        @media (max-width: 600px) {
            .started-inner-title {
                font-size: 30px;
            }
            .started-cards {
                img {
                    width: 300px;
                    padding-bottom: 1rem;
                }

                img:last-of-type {
                    padding-bottom: 0;
                }
            }
        }

        @media (max-width: 1200px) {
            .started-cards {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        
        .started-inner-description {
            color: #000;
            font-size: 18px;
            margin-top: 64px;

            .support-link {
                color: #000;
                text-decoration: underline;
                line-height: 150%;
            }
        }
    }
}
