.header-button {
    width: 160px;
    min-height: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-header__actions-login {
    margin-left: 28px;
    border: 2px solid #fff;
    border-radius: 20px;
}

@media (max-width: 400px) {
    .header-button {
        width: 100px;
    }
}
