.base_table {
  border-collapse: separate;
  width: 100%;
  border-spacing: 0;
  margin-top: 24px;

  thead {
    tr:first-child th:first-child {
      border-top-left-radius: 10px;
    }

    tr:first-child th:last-child {
      border-top-right-radius: 10px;
    }

    tr {

      th:first-child {
        padding-left: 52px;
      }

      th {
        padding: 24px 20px 24px 20px;
        white-space: nowrap;
        user-select: none;
        @extend %M2;

        .sort {
          cursor: pointer;
          position: relative;

          .sort_icon {
            position: absolute;
            right: 100%;
            padding-right: 8px;

            span {
              font-size: 18px;
            }
          }

          &.active {
            .sort_icon span {
              color: #07ACA6;
            }

            .text {
              color: #07ACA6;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      background-color: #F9FBFC;
      position: relative;
      overflow-x: hidden;

      &.even {
        background-color: white;
      }

      &:hover {
        background-color: #F9FBFC;
      }

      &.clickable {
        cursor: pointer;
        transition: background-color 0.3s;
      }

      &.clickable:hover {
        > td:first-child {
            position: relative;
        }

        > td:first-child:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 2px;
          background-color: $PRIMARY-COLOR;
        }
      }

      td {
        height: 56px;
        padding: 8px 20px 8px 20px;

        span {
          input {
            width: 100%;
            max-height: 40px;
            padding: 12px 16px;
            border-radius: 5px;

            &:focus, &:active {
              outline: 1px solid #07ACA6;
            }
          }
        }
        

        &:last-child {
          
        }
      }
    }

    .text {
      @extend %M2_light;

      .user-type {
        border-radius: 100px;
        font-size: 14px;
        padding: 2px 8px;
        color: rgba(92, 109, 118, 0.5);
        background: rgba(69, 90, 100, 0.1);
        margin-left: 5px;
      }
    }
  }

  tr {
    td:first-child {
      padding-left: 52px;
    }

    td:last-child {
      padding-right: 52px;
    }
  }

}
