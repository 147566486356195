.account-detail {
  max-width: 76vw;
  &__back {
    display: flex;
    font-size: 16px;
    //padding: 0 5em;
    font-weight: 500;
    //margin-top: 2em;
    margin-bottom: 2em;
    align-items: center;
    cursor: pointer;

    > span {
      font-size: 15px;
    }
  }

  &__header {
    //padding: 0 5em;
    white-space: nowrap;
    margin-top: 2em;

    &__info {
      display: flex;
      justify-content: space-between;
      background: #07ACA6;
      border-radius: 20px 20px 0px 0px;
      padding: 1em;

      .view-element__label {
        color: rgba(255, 255, 255, 0.7);
      }

      .view-element__description {
        color: #FFFFFF;
      }
    }
  }

  > .tabbed-page {
    //padding: 0 5em;

    > .tabbed-page-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      overflow-x: auto;
    }

    .tabbed-page__info {
      padding-top: 0;

      & .base-table {
        margin-top: 0;
      }
    }
  }

  &__table {
    margin-top: 24px;
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4em;

    &>div {
      width: 316px;
      margin-left: 24px;

      &.active {
        height: 21px;
        
        span {
          padding: 3px 10px;
          color: #0AAF60;
          background: linear-gradient(0deg, rgba(10, 175, 96, 0.1), rgba(10, 175, 96, 0.1)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
          border-radius: 100px;
        }
      }
    }
  }

  &__field.odd {
    background: #F9FBFC;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
  }

  &__show-buttons {
    justify-content: space-between;
  }

  &__note {
    margin-top: 24px;
    padding: 0 24px;
  }

  table {
    margin-top: 0;
  }
}
