.view-element {
  padding: 10px;
  gap: 12px;

  &.odd {
    background-color: #F9FBFC;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #5C6D76;
    font-size: 14px;
  }

  &__description {
    color: #455A64;
    font-size: 14px;
    align-items: center;
    display: flex;
    white-space: nowrap;
  }
}
