@import "src/styles/mixins";

@mixin date-input {
  align-items: center;
  margin-top: 5px;
  gap: 16px;
  max-width: 242px;
  height: 45px;
  background: #FFFFFF;
  border: 1px solid #909090;
  border-radius: 8px;
  &.error {
    border: 1px solid #d64751;
    color: #455A64;
  }
}

.inputField {
   height: 44px;
}

.registration--labelf {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  width: 500px;;
}

.registration-dropdown--labelf {
  text-align: right;
  font-family: 'Indivisible';
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 120%; /* 14.4px */
letter-spacing: 0.1px;

}

.field-container {
  @include base-margin;

  input {
    font-size: 14px;
    font-weight: 500;

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }

    &::placeholder {
      font-size: 14px;
      color: #9F9F9F;
    }

    &.isEmpty {
      &[type='date'] {
        color: #9F9F9F;
      }
    }
  }
}

.field-container--date {
  height: 69px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.field-label {
  @include field-label;
  font-size: 14px;
}

.field-error {
  color: #D64751;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
}
.field-error-text {
  margin-left: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
    color: #D64751;
}

.field-description {
  margin-left: 5px;
  color: var(--main-colors-gray-0, #9F9F9F);
  font-size: 12px;
  font-family: 'Indivisible';
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.1px;
}


.field-input {
  @include field-input;
  @include input-status;
  font-weight: 500;
  &.error {
    padding: 14px;
    color: #455A64;
    border: 1px solid #d64751;
    background-image: url(../../../../../assets/Vector_Error.svg);
  background-repeat: no-repeat;
  background-position: right 15px center;
  }
}

.field-input[type="date"] {
  justify-content: flex-start;
}


input[type="date"] {
  -webkit-text-fill-color: #000;

  &::-webkit-calendar-picker-indicator {
    background-image: url(../../../../../assets/calendar_month.svg);
  }

  &.error::-webkit-calendar-picker-indicator {
    background-image: none ;
  }
}

.fieldTonnes {
  // text-align: right;
  margin-top: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;


  @include input-status;&.error {
    text-align: left;
    border: 1px solid #d64751;
  } 
}
.fieldGHG {
  // text-align: right;
  margin-top: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 14px;
  gap: 5px;
  width: 500px;
  height: 45px;
  background: #F6F7F8;
  border: 1px solid #9F9F9F;
  border-radius: 8px;
  @include input-status;
}

.fieldPost {
  flex: 1;
  margin-top: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 14px;
  max-width: 100px;
  margin-right: 0;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #909090;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  @include input-status;&.error {
    color: #455A64;
    border: 1px solid #d64751;
  }
}

.fieldCity {
  margin-right: 0;
  flex: 1;
  margin-top: 5px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 14px;
  max-width: 384px;
  height: 44px;
  background: #FFFFFF;
  border: 1px solid #909090;
  border-radius: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  @include input-status;
  &.error {
    color: #455A64;
    border: 1px solid #d64751;
    padding: 14px;
  max-width: 384px;
  margin-right: 0;
  padding: 14px;
  }
}

.fieldACQ {
  @include date-input;
  @include input-status;
  &.error {
    color: #455A64;
    border: 1px solid #d64751;
  }
}
.fieldEntry {
  @include date-input;
  @include input-status;
  &.error {
    color: #455A64;
    border: 1px solid #d64751;
  }
}

.field-generate-token {
  @include field-input;
  @include input-status;
  &.error {
    padding: 14px;
    color: #455A64;
    border: 1px solid #d64751;
    background-image: url(../../../../../assets/Vector_Error.svg);
  background-repeat: no-repeat;
  background-position: right 15px center;
  }
  width: 791px;
  font-weight: 500;
}

