@import 'src/styles/mixins';
@import "src/styles/extends";

.statements {
  min-width: 95vw;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1em;
    margin-bottom: 16px;

    .statements__header_text {
      font-weight: 500;
      font-size: 24px;
      margin: 0;

      @include tablet {    
        font-size: 18px;
        margin-bottom: 12px;
      }
    }

    @include tablet {
      flex-direction: column;
      align-items: center;
    }
  }

  &__filters {
    display: flex;
    align-items: center;

    & > div {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    @include phone {    
      width: 100%;
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }

  &__table {

    .pdf-link > span {
      color: #07ACA6;
    }
  }
}
