@import 'material-icons/iconfont/material-icons';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/dropdown';

@import 'font';
@import 'base';
@import 'colors';
@import 'font';

@import 'table';
@import 'mixins';

@import 'transfer';

.has-striped-rows .ant-table:not(.ant-table-empty) {
  tbody tr:nth-child(odd) td {
    background-color: #f9fbfc;
  }
}

.has-striped-rows {
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    border-top: none;
    border-bottom: none;
  }
}

// Ant specific overrides
.ant-tag {
  border-radius: 100px;
}

a.ant-typography,
a.ant-typography > .material-icons-outlined,
a.ant-typography > .material-icons {
  color: #07aca6;
}
//
//tr.ant-table-row > td.ant-table-cell-row-hover:first-child:before {
//  content: '';
//  position: absolute;
//  left: 0;
//  bottom: 0;
//  top: 0;
//  width: 2px;
//  background-color: #07aca6;
//}

.ant-table-column-sorters {
  flex-direction: row-reverse;
  gap: 0.5rem;
}

.ant-table-column-sort > .ant-table-column-sorters > .ant-table-column-title {
  color: #07aca6;
}

.ant-table-thead th {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
}

.c-rsb-pagination {
  font-size: 12px;

  .ant-pagination-options {
    &:before {
      content: 'Rows per page ';
      padding-right: 5px;
    }
  }

  .ant-select,
  .ant-select-selector,
  .ant-pagination-options-quick-jumper input {
    font-size: 12px !important;
  }

  .ant-pagination-item-link:disabled {
    opacity: 0.5;
  }
}

.ant-input--no-controls {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &,
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-week-field,
::-webkit-datetime-edit-year-field {
  background-color: transparent;
}

sub {
  vertical-align: bottom;
  line-height: 100%;
}
