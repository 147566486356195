.reject-modal {
  text-align: center;
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
      margin-right: 12px;
      color: #FF3D00;
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: center;

    > :first-child {
      margin-right: 10px;
    }
  }
}
