.bcu-registration {
  min-width: 95vw;
  
  &__form {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    margin: 20px 0;

    &__expired-certificate {
      border-radius: 100px;
      color: #FFFFFF;
      background: #e81a1a;
      padding: 20px;
      text-align: center;
    }

    h1 {
      font-weight: 500;
      font-size: 24px;
    }

    .custom-input input {
      font-size: 16px;
      padding: 0 10px;
      color: rgb(51, 51, 51);
    }
  }
}
