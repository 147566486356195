.facilitator-tab {
  padding: 30px;
  width: 50%;
  & > * {
    min-height: 56px;
  }
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__select-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > * + * {
      margin-left: 20px;
    }
  }

  &__actions {
    align-self: flex-end;
    margin-top: 20px;
    > * + * {
      margin-left: 20px;
    }
  }
}

.select-facilitator {
  width: 20rem;
}
