@import "src/styles/extends";

.registration {
  display: flex;
  flex-direction: column;
  padding: 1em 4em;

  &__welcome {
    margin-bottom: 2em;
    h2 {
      @extend %M1;
    }

    .under_h {
      @extend %M2_light;
      color: #5C6D76;
    }
  }

  &__form_data {
    display: flex;
    background-color: white;
    border-radius: 20px;

    .register_navigation {
      flex: 0;
      min-width: 376px;
      padding-left: 32px;
      padding-right: 32px;
      padding-top: 150px;
    }

    form {
      flex: 1;
      padding: 32px 72px 32px 48px;
      border-right: 1px solid rgba(69, 90, 100, 0.1);
    }
    .title {
      @extend %M1_2;
      letter-spacing: 0.1px;
      margin-bottom: 16px;
      padding-bottom: 8px;
    }

    .form_block {
      display: flex;
      flex-direction: column;
      margin-bottom: 36px;
      gap: 16px;
    }

    .errors {
      margin-top: 32px;
    }
  }

  &__select {
    & > div {
      margin: 0;
    }

    input {
      width: 100%;
    }
  }

  &__RSB {
    gap: 16px 0;
  }

  label {
    margin-top: 0;
  }

  small {
    color: #5C6D76;
  }
}
