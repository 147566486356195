.transactions {
  min-width: 95vw;

  .transaction-table_wrap{
    margin-top: 1rem;
    padding: 10px;

    border-radius: 20px;
    background-color: #ffffff;

    .ant-pagination {
      margin-right: 16px;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin-right: 1rem;
    min-width: 150px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    min-height: 20px;

    .transactions__header_text {
      font-weight: 500;
      font-size: 16px;
      margin: 0;
    }
  }

  &__filters {
    display: flex;
    align-items: center;

    .wide-filter {
      min-width: 280px;
    }

    &__download {
      margin-right: 10px;
    }

    & > div {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &__container {
    margin-bottom: 32px;
    border-radius: 20px;
    background: #FFFFFF;
    padding: 20px 0;
  }

  &__info {
    text-align: left;

    &__header {
      color: rgba(92, 109, 118, 0.5);
      font-weight: 500;
      font-size: 14px;
      min-height: 30px;
    }

    &__details {
      color: #455A64;
      font-size: 14px;
      min-height: 30px;
    }

    &__link {
      cursor: pointer;
    }

    &__volume {
      font-size: 14px;

      &.plus {
        color: #0AAF60;
      }

      &.minus {
        color: #F9434E;
      }
    }
  }

  &__operations {
    margin-top: 30px;

    &_title {
      font-weight: 500;
      font-size: 24px;
      margin: 16px 0;
    }
  }
}

@media (max-width: 600px) {
  .transactions {
    .transaction-table_wrap {
      overflow-x: auto;

      .transactions__header {
        flex-direction: column;

        .transactions__filters {
          flex-direction: column;
          align-items: flex-start;

          div {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}
