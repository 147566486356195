.step {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 404px;
  height: 56px;
  border-radius: 16px;
  flex: none;
  order: 1;

  &:hover {
    cursor: pointer;
    background-color: #F6F7F8;
  }

  .circle {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-filled {
      background: #07ACA6;
    }

    &-active {
      background: #07ACA6;
    }
    &-inactive {
      background: #FFFFFF;
      border: 1px solid #07ACA6;
    }
  }

  .circle-text {
    margin: 0;
    font-family: 'Indivisible', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    &-active {
      color: #FFFFFF;
    }
    &-inactive {
      color: #07ACA6;
    }
  }

  .step-text {
    width: 280px;
    height: 36px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    border-radius: 16px;
    font-family: 'Indivisible', serif;
    font-style: normal;
    font-weight: 500;

    .step-title {
      padding: 0;
      margin: 0;
      height: 17px;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0.1px;
      color: rgba(92, 109, 118, 0.5);
    }

    .step-description {
      padding: 0;
      margin: 0;
      height: 19px;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: 0.1px;
      color: #5C6D76;
      white-space: nowrap;
    }
  }

  &.error {
    color: #455A64;
    border: 1px solid #d64751;

    .circle-inactive {
      background: #d64751;
    }
  }
  &:hover .circle-inactive {
    background-color: #adcccb;
  }
}

.svg-incircle {
  padding: 0.5em;

  &-active {
    fill: #FFFFFF;
  }

  &-inactive {
    fill: #07ACA6;
  }
}

.svg-container {
  padding-bottom: 5px;
}
