.submissions-details {
  min-width: 78vw;
  width: 88vw;

  &__back {
    display: flex;
    font-size: 16px;
    //padding: 0 5em;
    font-weight: 500;
    //margin-top: 2em;
    margin-bottom: 2em;
    align-items: center;
    cursor: pointer;

    > span {
      font-size: 15px;
    }
  }

  &__header {
    white-space: nowrap;
    margin-top: 2em;

    &__info {
      display: flex;
      justify-content: space-between;
      background: #07aca6;
      border-radius: 20px 20px 0 0;
      padding: 1em;
      color: #ffffff;
      font-size: 14px;

      .view-element__label {
        color: rgba(255, 255, 255, 0.7);
      }

      .view-element__description {
        color: #ffffff;
      }
    }
  }

  > .tabbed-page {
    > .tabbed-page-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .tabbed-page__info {
      padding-top: 0;

      & .base-table {
        margin-top: 0;
      }
    }
  }

  .admin-users-info__profile__header {
    margin-left: 24px;
  }

  .row {
    justify-content: flex-start;
  }

  .view-elements-with-rows .row:nth-child(2n + 1) {
    background: #f9fbfc;
  }

  &__action-buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
