.account-options {
  text-align: center;
  padding: 85px 165px 45px 165px;

  .account-options-title {
    font-size: 40px;
    margin: 0 0 19px 0;
    color: #000;
  }

  .account-options-desc {
    color: #000;
    font-size: 18px;
    margin: 0;
    margin-bottom: 30px;
  }
}

.account-options-table-wrap {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    
    .account-options-table {
      min-width: 800px;
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;
      border: none;
    }
}

.account-options-table th,
.account-options-table td {
  border: 1px solid #767676;
  background-color: #fff;
  padding: 0;
}

.account-options-table th {
  background-color: #fff;
  border-top: none;
  font-size: 24px;
  line-height: 120%;
  color: #000;
  font-weight: 600;
  padding-bottom: 5px;
}

.account-options-table {
    .first-header-cell {
        border-left: none;
    }
    
    .last-header-cell {
        border-right: none;
    }
}

.account-options-table td {
  text-align: left;
  border-top: none;
  border-bottom: none;
}

.account-options-table span {
  display: block;
  margin: 4px 0;
  color: teal;
  font-weight: bold;
}

.account-options-table {
    .account-table-float-title {
        position: absolute;
        margin-top: -4.5rem;
        right: 43%;
        font-size: 24px;
        line-height: 120%;
        color: #000;
        font-weight: 600;
    }

    .table-head-row {
        position: relative;
    }

    .grouped-cell {
        font-size: 20px;
        font-weight: 400;
        border-left: none;
        border-right: none;
        width: 30px;
    }
}

.table-body {
   .body-cell {
      color: #000;
      font-size: 16px;
      line-height: 140%;
   }

   .first-body-cell {
      border-left: none;
      border-right: none;
      display: flex;
      margin-left: 10px;
      margin-top: 20px;

      h5 {
        font-size: 16px;
        margin: 0;
        font-weight: 600;
      }

      p {
        font-style: italic;
      }

      .first-body-cell-img {
        margin-right: 17px;
      }
   }

   .second-body-cell {
        width: 90px;
        text-align: center;
   }
   
   .third-body-cell {
        font-size: 20px;
        line-height: 120px;
        text-align: center;
   }

   .last-body-cell {
        font-size: 20px;
        line-height: 120px;
        border-right: none;
        text-align: center;
   }
}

.brackets-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    .bracket {
        color: #0C9992;
        font-size: 18px;
    }
}

.table-note {
    margin: 24px 0 0 0;
    display: flex;
    align-items: center;

    .on-behalf {
      font-size: 16px;
      color: #000;
    }
}

.intro-btn {
  cursor: pointer;
  background-color: #DC7534;
  margin-top: 50px;
  border: none;
  border-radius: 40px;
  font-size: 25px;
  font-weight: 600;
  height: 80px;
  padding: 25px 85px;

  a {
      color: #fff;
  }
}

@media (max-width: 998px) {
  .account-options {
    padding: 85px 65px 45px 65px;
  }
}

@media (max-width: 768px) {
  .intro-btn {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .account-options {
    padding: 50px 30px;

    .account-options-title {
      font-size: 30px;
    }

    .intro-btn {
      padding: 25px 50px;
    }
  }
}
