.excel-source-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .ant-upload-list {
    .ant-upload-list-item {
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;

      .ant-upload-list-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }
  }
}

.excel-source-template-btn {
  min-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.excel-source-template-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
  justify-content: space-between;
}
