.transfer_container .custom-input input {
    font-size: 16px;
    padding: 0 10px;
    color: rgb(51, 51, 51);
}

.retirement-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}