.submissions-list {
  $self: &;
  padding-top: 1em;
  min-width: 95vw;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .submissions-list__header_text {
      font-weight: 500;
      font-size: 24px;
      margin: 0;
      align-self: center;
    }

    &__controls {
      display: flex;
      width: 800px;

      & > :not(:last-child) {
        margin-right: 10px;
      }

    }
  }

  &__status {
    border-radius: 100px;
    font-size: 14px;
    padding: 2px 8px;
    white-space: nowrap;

    &.consideration {
      background: rgba(69, 90, 100, 0.1);
      color: #5C6D76;
    }

    &.approved {
      background: rgba(10, 175, 96, 0.1);
      color: #0AAF60;
    }

    &.declined {
      background: rgba(255, 61, 0, 0.1);
      color: #FF3D00;
    }
  }
}
