@import 'src/styles/mixins';

.footer {
  padding: 0 240px;
  background-color: #064760;

  .footer-main {
    display: flex;
    margin-top: 100px;

    .footer-column {
      width: 30%;
      margin-bottom: 76px;

      .footer-logo-wrap {
        display: flex;

        .footer-logo-title {
          color: #fff;
          margin: 0 2px 0 12px;
          font-size: 30px;
        }

        .footer-logo-img {
          margin-top: 12px;
        }
      }

      .footer-additional-wrap {
        margin: 6px 0 12px 0;
        color: #fff;
        width: 115px;

        p {
          margin: 0;
          font-size: 10px;
          text-align: right;
        }

        .footer-additional-second {
          width: max-content;
        }
      }

      .footer-additional-third {
        width: 115px;
        text-align: right;
        margin: 0;

        a {
          font-size: 10px;
          color: #fff;
        }
      }

      .footer-nav-title {
        margin: 0 0 12px 0;
        font-size: 14px;
        color: #fff;
      }

      .footer-nav-list {
        .footer-nav-item {
          margin: 4px 0;

          a {
            font-size: 14px;
            font-weight: 300;
            color: #fff;
          }
        }
      }
    }

    .first-column {
      width: 40%;
    }
  }

  .footer-desc {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin: 25px 0;
    text-align: center;
  }
}

@media (max-width: 998px) {
  .footer {
    padding: 0 100px;
  }
}

@media (max-width: 668px) {
  .footer {
    padding: 0 25px;
  }
}

@media (max-width: 500px) {
  .footer {
    padding: 0 10px;
  }
}
