.drop-area {
  margin-left: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
.btn-up {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  width: 92px;
  height: 30px;
  background: #07aca6;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: #06a096;
  }
  &:active {
    background-color: #058e88;
  }
  &:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
  }
}
  
.btn-up-text {
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.1px;
  width: 60px;
  height: 14px;
  font-size: 12px;
  color: #ffffff;
  white-space: nowrap;
}
