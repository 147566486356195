@import 'src/styles/mixins';

.contact-us {
  cursor: pointer;
  color: #FFFFFF;
  font-weight: 300;
  font-size: 16px;
  margin-right: 66px;

  @include phone {
    padding: 0;  
    margin-right: 8px;
    font-size: 14px;
  }

  &__icon {
    color: rgba(255, 255, 255, 0.5);
    margin-right: 10px;
  }
}

@media (max-width: 960px) {
  .contact-us {
    margin-right: 32px;
  }
}
