.contact-us-page {
  margin-top: 30px;
  border-radius: 20px;
  background: #FFFFFF;
  padding: 20px;
}

.contact-us-group {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
