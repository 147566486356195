.c-edit-buttons {
  display: flex;
  width: 100%;
  padding: 24px 12px 0;
  gap: 10px;
  align-items: center;
  justify-content: end;

  &__button {
    color: #fff;
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 112px;
    background: #07ACA6;
    border-radius: 100px;
    border: none;
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;

    &:hover {
      background-color: #06A096;
    }

    &:active {
      background-color: #058E88;
    }

    &:disabled {
      background-color: #5C6D7680;
      cursor: not-allowed;
    }

    &--cancel {
      background-color: #fff;
      color: #06A096;
      border: 1px solid #06A096;

      &:hover, &:active {
        background-color: #fff;
      }

      &:disabled {
        background-color: #5C6D7680;
      }
    }
  }

  &__button-text {
    height: 20px;
    font-family: 'Indivisible', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: inherit;
    white-space: nowrap;
  }
}