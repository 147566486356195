@import "src/styles/colors";
@import 'src/styles/mixins';

.date-select {
  position: relative;
  cursor: pointer;

  @include phone {
    margin-right: 0 !important;
  }

  &__filter-icon {
    left: 16px;
    font-size: 16px;
    color: #07ACA6;
  }

  &__field {
    width: 232px;
    position: relative;

    @include phone {
      width: 100%;
      margin-bottom: 8px;
    }

    & > input {
      font-weight: 500;
      width: 100%;
      height: 41px;
      padding: 14px;
      border-radius: 8px;
      color: #757779;
      background-color: #455A641A;
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 14px;
    }

    & > span {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      color: #CCCCCC;
    }

    &.filled {
      input {
        padding-left: 54px;
        border: 1px solid #07ACA6;
        color: #07ACA6;
      }
    }

    &.active {
      & > span {
        &:last-child {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &::after {
      content: '';
      width: 1px;
      height: 24px;
      background-color: #CFD2D5;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
    }
  }

  &__panel {
    width: 318px;
    min-height: 153px;
    padding: 16px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #455A641A;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 0;
    top: 41px;
    z-index: 100;

    @include phone {
      width: 100%;
      max-width: 305px;
      padding: 8px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.timeRowError {
      margin-bottom: 5px;
    }

    &:first-child {
      margin-bottom: 12px;
    }
  }

  &__input {
    position: relative;

    &:first-child {
      &::after {
        content: '';
        width: 10px;
        height: 1px;
        background-color: #455A64;
        position: absolute;
        top: 50%;
        right: -17px;
        transform: translateY(-50%);
      }
    }

    & > input {
      width: 130px;
      height: 40px;
      padding-left: 36px;
      border: 1px solid #ADB6BA;
      border-radius: 8px;
      outline: none;

      &:focus, &:active {
        border: 1px solid #07ACA6;
      }
    }

    input[type="time"]::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    &.timeError {
      input {
        border: 1px solid #D64751;
      }
    }

    &.filled {
      input {
        border: 1px solid #9F9F9F;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 18px !important;
  }

  &__error {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #D64751;
    font-size: 18px !important;
  }

  &__actions {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    color: $PRIMARY-COLOR;
    cursor: pointer;

    &.disabled {
      color: #455a64ad;
      pointer-events: none;
    }
  }

  &__calendar {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    position: absolute;
    left: 0;
    top: 40px;
    z-index: 997;

    @include phone {
      font-size: 10px;
    }
  }
}

.rdrDateDisplayWrapper {
  display: none;
}
