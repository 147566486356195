.user-balance {
  width: 95vw;
  
  .user-balance__header__arrow {
    width: 24px;
  }

  .base_table {
    margin-top: 0;
  }

  .search {
    user-select: none;

    &__input {
      min-width: 276px;
      padding-left: 0;
      padding-right: 48px !important;
      outline: none;
    }

    &__icon {
      user-select: none;
    }
  }

  &-credits {
    padding: 10px;
  }

  &__header {
    $header: &;

    display: flex;
    align-items: center;
    padding: 15px 28px;
    background: rgba(69, 90, 100, 0.1);
    border-radius: 20px;
    cursor: pointer;
    user-select: none;
    transition: all 0.1s;

    &__info {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;

      .totalContainer {
        display: flex;
      }
    }

    & .view-element {
      &__label {
        white-space: nowrap;
      }
    }

    &.opened {
      background-color: #07aca6;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      #{$header}__arrow {
        width: 24px;
        span {
          color: #ffffff;
        }
      }

      & .view-element {
        &__label {
          color: rgba(255, 255, 255, 0.7);
        }

        &__description {
          color: #ffffff;
        }
      }
    }

    &:not(&.opened):hover {
      background: rgba(69, 90, 100, 0.15);
    }
  }

  & > &__body {
    border-radius: 0 0 20px 20px;
    background-color: #ffffff;

    & > .table-wrapper {
      border-radius: 0 0 20px 20px;

      & > .base_table {
        margin-top: 0;
      }
    }
  }
}

.select-facilitator {
  width: 20rem;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-table-row {
  .ant-checkbox:not(.ant-checkbox-checked) {
    display: none;
  }

  &:hover {
    .ant-checkbox {
      display: block;
    }
  }
}

.wide-select_wrap {
  width: 220px;
}

@media (max-width: 600px) {
  .user-balance {
    margin-top: 1rem;
    
    .user-balance__header {
      overflow-x: auto;
    }
  }
}