.custom-select {
  &.custom_select_lock {
    & > div {
      background-color: #E6E6E6;
      border-color: #9F9F9F;
    }
  }

  &.isError {
    & > div {
      border-color: #d64751;
    }
  }

  svg {
    cursor: pointer;
  }

  &__wrapper {
    width: 100%;
    min-width: 135px;
  }

  margin: 0;
  border-radius: 5px;

  &__label {
    display: block;
    margin-top: 15px;
    margin-bottom: 5px;
    padding-left: 3px;

    &--required:after {
      content: '*';
      color: #FF3D00;
    }
  }

  &_lock {
    background: rgba(69, 90, 100, 0.3);
  }

  &__error, &__description {
    font-size: 12px;
    padding: 0 0 5px 5px;
    text-align: left;
    margin-top: 5px;
  }

  &__error {
    color: #D64751;
  }

  &__description {
    color: rgba(92, 109, 118, 0.5);
  }
}
