.next-btn {
  color: #fff;
  margin-top: 30px;
  margin-left: 388px;
  padding: 10px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 112px;
  background: #07ACA6;
  border-radius: 100px;
  border: none;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;

  &:hover {
    background-color: #06A096;
  }

  &:active {
    background-color: #058E88;
  }

  &:disabled {
    background-color: #5C6D7680;
    cursor: not-allowed;
  }

  .next-btn--text {
    height: 20px;
    font-family: 'Indivisible', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
    color: inherit;
    white-space: nowrap
  }

  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  &__loader {
    position: relative;
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 7.5px;
    margin-left: 7.5px;
  }

  &__loader:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: #07ACA6;
    animation: spinner .6s linear infinite;
  }
}
