.status-account--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 0;
  height: 21px;
  width: fit-content;

  &.active, &.approved, &.deposited {
    background: linear-gradient(0deg, rgba(10, 175, 96, 0.1), rgba(10, 175, 96, 0.1)),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);

    span {
      color: #0AAF60;
    }
  }

  &.retired, &.removed, &.merged {
    background: linear-gradient(0deg, rgba(7, 172, 166, 0.1), rgba(7, 172, 166, 0.1)), #FFFFFF;
  }

  &.inactive, &.denied, &.split {
    background: linear-gradient(0deg, rgba(255, 61, 0, 0.1), rgba(255, 61, 0, 0.1)),
      linear-gradient(0deg, #FFFFFF, #FFFFFF);

    span {
      color: #FF3D00;
    }
  }

  &.suspended, &.transcribed, &.failed {
    background: linear-gradient(0deg, rgba(255, 229, 0, 0.1), rgba(255, 229, 0, 0.1)), #FFFFFF;
  }

  &.closed {
    background: #F6F7F8;
  }

  &.disconnected, &.pending, &.transit, &.deposited {
    background: var(--gary-10, rgba(69, 90, 100, 0.10));

    span {
      color: #5C6D76;
    }
  }

  .status-account--text {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.10px;
    border-radius: 100px;
    display: inline-block;
    padding: 2px 8px;

    &.active, &.approved {
      color: #0AAF60;
    }

    &.retired, &.removed, &.merged {
      color: #07ACA6;
    }

    &.inactive, &.denied, &.split {
      color: #FF3D00;
    }

    &.suspended, &.transcribed, &.failed {
      color: #DFC800;
    }

    &.closed {
      color: #5C6D7680;
    }

    &.disconnected, &.pending, &.transit, &.deposited {
      color: var(--text, #5C6D76);
    }
  }
}
